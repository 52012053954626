@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@600&display=swap');

html {
  overflow: hidden;
  height: 100%;
  position: fixed;
}

body {
  overflow: auto;
  height: 100%;
  position: relative;
  background-color: #293957;
  display: flex;
  justify-content: center;
  font-family: 'Dosis', sans-serif;
  font-size: 1.3rem;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
}

.logoContainer {
  display: flex;
  justify-content: center;
}



/* .logoShadow {
  margin-left: -54%;
  width: 55%;
  margin-top: 2%;
  opacity: 0;
  transition: opacity 1s;
  transition-timing-function: ease; 
  z-index: -1;
} */

.logoShadow:hover {
  opacity: 0.2;
}

.nav {
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navLink {
  margin-top: 2%;
  cursor: pointer;
  transition: opacity 1s, transform 1s;
}

#software {
  width: 30%;
}

#hardware {
  width: 35%;
}

#music {
  width: 18%;
}

#about {
  width: 19%;
}

.line {
  width: 20%;
}

#contentImage {
  height: auto;
  max-width: 40%;
  border-radius: 16px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


@media only screen and (min-width: 601px) {
  .logo {
    max-width: 55%;
    max-height: fit-content;
    width: auto;
    height: auto;
    margin-top: 2%;
    transition: opacity 1.5s, transform 1.5s;
  }

  .content {
    position: absolute;
    padding: 3%;
    background-color: #606c83;
    width: 44%;
    min-height: 52%;
    top: 15%;
    left: 25%;
    transition: opacity 2s, transform 2.5s;
    border-radius: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: rotateX(90deg);
    transition-timing-function: ease; 
  }

  .back {
    width: 10%;
    position: absolute;
    top: 90%;
    left: 80%;
    transition: opacity 2s;
    opacity: 0;
    cursor: pointer;
  }
} 


@media only screen and (max-width: 600px) {
  .logo {
    max-width: 55%;
    max-height: 100px;
    width: auto;
    height: auto;
    margin-top: 2%;
    transition: opacity 1.5s, transform 1.5s;
  }

  .content {
    position: absolute;
    padding: 2%;
    background-color: #606c83;
    width: 80%;
    min-height: 70%;
    top: 10%;
    left: 10%;
    transition: opacity 2s, transform 2.5s;
    border-radius: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: rotateX(90deg);
    transition-timing-function: ease; 
  }

  .back {
    width: 16%;
    position: absolute;
    top: 90%;
    left: 80%;
    transition: opacity 2s;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
  }
}

